define('m20/controllers/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',

    selectedNodes: "",

    init: function init() {

      this._super();

      var lasteinwirkung = this.controllerFor('lasteinwirkung');
      var bauteile = this.controllerFor('bauteile');
      if (!lasteinwirkung.get('lasteinwirkungInvalid') || !bauteile.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }
    },

    watchInvalidScrewsCombo: (function () {

      var self = this;

      if (self.showinvalidscrews) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, false);
        self.set('schraubenliste', core);
      }
    }).observes('showinvalidscrews'),

    watchSelectedScrew: (function () {

      var self = this;
      var application = self.controllerFor('application');
      var downloadcenter = self.controllerFor('downloadcenter');

      var ergebnis = self.controllerFor('ergebnis');

      console.log(self.jstreeStateOptionHash);

      if (self.selectedNodes.length > 0) {

        if (self.selectedNodes[0].data.VM_Okay === "True") {

          var firm = application.get('model').application.objectAt(0).get('firm').toLowerCase();
          var cC = application.get('cC').toLowerCase();

          application.set('verbindungsmittelInvalid', false);
          application.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          application.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          self.set('validScrewSelected', true);

          downloadcenter.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          downloadcenter.set('schraubenCAD', self.selectedNodes[0].data.CAD);

          downloadcenter.set('etaNummer', self.selectedNodes[0].data.ETA.substring(3));
          downloadcenter.set('eta', "https://timberconnect.de" + Object.values(etaConfig[firm][cC]["files"]["ETAs"]).findBy('name', self.selectedNodes[0].data.ETA)["pfad"].replace("..", "") + Object.values(etaConfig[firm][cC]["files"]["ETAs"]).findBy('name', self.selectedNodes[0].data.ETA)["dateiname"]);
          downloadcenter.set('etaFehlt', false);

          // if(self.selectedNodes[0].data.ProductInfo !== ""){
          //   downloadcenter.set('produktkatalog', Object.values(etaConfig[firm][cC]["files"]["ETAs"]).findBy('name', self.selectedNodes[0].data.ProductInfo)["pfad"]+Object.values(etaConfig[firm][cC]["files"]["ETAs"]).findBy('name', self.selectedNodes[0].data.ProductInfo)["dateiname"]);
          //   downloadcenter.set('katalogFehlt', false);
          // }

          downloadcenter.set('validScrewSelected', true);

          ergebnis.set('pdfErstellt', false);

          this.ergebnisgrafik();
        } else {

          application.set('verbindungsmittelInvalid', true);
          self.set('validScrewSelected', false);

          downloadcenter.set('validScrewSelected', false);
          downloadcenter.set('etaNummer', "");
          downloadcenter.set('validScrewSelected', true);
          downloadcenter.set('katalogFehlt', true);
          downloadcenter.set('etaFehlt', true);

          var application = this.controllerFor('application');
          var x3d = application.get('model').m06default.objectAt(0);
          var bauteile = x3d.get('bauteile');

          x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
          x3d.set('ergebnisGeladen', false);
          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('transformHelper', !x3d.get('transformHelper'));
        }
      }
    }).observes('selectedNodes'),

    ergebnisgrafik: function ergebnisgrafik() {

      var self = this;

      var selectedScrew = self.selectedNodes[0].data.ArtNr;
      var cidGrafik = self.calcid;

      var application = self.controllerFor('application');
      var store = application.get('store');
      var arrayHelper = [];

      var x3d = application.get('model').m06default.objectAt(0);

      // alte Schrauben "Verbindung (has-many)" aus m06default.get('bauteile') löschen
      application.get('model').m06default.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'verbindungsmittel') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        application.get('model').m06default.objectAt(0).get('bauteile').removeObject(item);
      });

      var server = application.get('server');
      var pfad = application.get('pfad');

      $.ajax({
        type: "POST",
        url: server + "getGrafik/",
        data: {
          cid: cidGrafik,
          artnr: selectedScrew
        }
      }).done(function (data) {

        console.log('Daten der ausgewählten Schraube');
        console.log(data);

        var dataObject = JSON.parse(data);

        dataObject.bauteile.forEach(function (item) {

          var newID = item.id;
          if (item.typ === "verbindungsmittel") {
            newID = "schraubeStandard1";
          }

          if (item.typ === "verbindungsmittel") {
            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: item.point,
              translations: item.point,
              rotations: [item.richtungsvektor],
              richtungsvektor: [item.richtungsvektor],
              appearances: [newID],
              diffusecolors: [newID],
              screwProperties: [item.verbindungsmittel]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          }

          dataObject.richtungsvektoren.forEach(function (item) {
            var r = store.push('richtungsvektor', item);
            r.save();
          });
        }); //Bauteile

        dataObject.punkte.forEach(function (item) {
          var p = store.push('translation', item);
          p.save();
        });

        dataObject.verbindungsmittel.forEach(function (item) {
          var screwProp = store.push('screw-property', item);
          screwProp.save();
        });

        dataObject.richtungsvektoren.forEach(function (item) {
          var r = store.push('rotation', item);
          r.save();
        });

        dataObject.richtungsvektoren.forEach(function (item) {
          var r = store.push('richtungsvektor', item);
          r.save();
        });

        x3d.set('ergebnisGeladen', true);
        x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

        self.controllerFor('application').zentriertObjekt();
      });
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var selectedScrew = self.selectedNodes[0].data.ArtNr;
        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');

        applicationdata.set('selectedScrew', selectedScrew);

        self.set('loading', true);

        var server = application.get('server');
        var pfad = application.get('pfad');

        $.ajax({
          type: "POST",
          url: server + "getKompaktergebnis/",
          data: {
            cid: self.calcid,
            artnr: selectedScrew
          }
        }).done(function (data, statusText, xhr) {

          self.controllerFor('supercontroller').logaufruf("getKompaktergebnis", xhr.status);

          var ergebnis = self.controllerFor('ergebnis');

          var downloadcenter = self.controllerFor('downloadcenter');
          downloadcenter.set('pdfErstellt', false);

          var html = data;
          var bEnd, bStart;
          bStart = html.indexOf("<div class='headline'");
          bEnd = html.indexOf("</body");
          var body = html.slice(bStart, bEnd);

          ergebnis.set('kompaktergebnis', body.toString());
          ergebnis.set('pdfErstellt', false);
          ergebnis.send('setKompaktergebnis');
          self.transitionToRoute('ergebnis');
          self.set('loading', false);
        });
      },

      logNode: function logNode(event) {
        var self = this;
        console.log(self.selectedNodes);
        console.log(self.selectedNodes[0].text);
      }
    }
  });

});